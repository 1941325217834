import React from "react";

export const Header: React.FC = () => {
  return (
    <header className="flex justify-between items-center my-8 mx-12 md:mx-16">
      <h2 className="text-2xl transition text-white hover:text-gray-900 font-bold cursor-pointer">
        Voistra
      </h2>
    </header>
  );
};

export default Header;
