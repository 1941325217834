import React from "react";

export const Homepage: React.FC = () => {
  return (
    <div className="mt-28 p-0">
      <div className="flex flex-col px-4 md:px-32 lg:px-48 justify-center">
        <div className="text-center space-y-10">
          <span className="text-4xl md:text-6xl lg:text-7xl font-bold text-white">AI-conducted interviews to simplify hiring</span>
          <p className="text-2xl text-white font-light">Save time, reduce bias, and find the right person for the job</p>
          <div className="md:px-16 lg:px-32">
            <div className="klaviyo-form-Tv6SYn" />
          </div>
        </div>
      </div>
      <div className="container" />
    </div>
  );
};

export default Homepage;
