import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Header from "./Header";
import Homepage from "./Homepage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div className="flex flex-col w-screen h-screen">
    <Header />
    <div className="flex-1">
      <Homepage />
    </div>
    <footer className="flex flex-shrink-0 justify-end my-6 mx-12 text-white">
      <p className="text-xs">© {new Date().getFullYear()} Voistra</p>
    </footer>
  </div>
);
